<div>
  <div class="slack-message" style="margin: 10px 0" #editorWrapper></div>
  <div class="toolbar">
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="insertEmoji()"
      ><ion-icon name="happy-outline"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="toggleBold()"
      ><ion-icon name="custom-bold"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="toggleItalic()"
      ><ion-icon name="custom-italic"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="toggleStrikethrough()"
      ><ion-icon name="custom-strikethrough"></ion-icon
    ></ion-button>
    <ion-button
      *ngIf="isRichText"
      fill="clear"
      class="btn-small btn-formatting"
      color="dark"
      (click)="toggleBulletList()"
      ><ion-icon name="custom-unordered-list"></ion-icon
    ></ion-button>
    <ion-button
      *ngIf="isRichText"
      fill="clear"
      class="btn-small btn-formatting"
      color="dark"
      (click)="toggleOrderedList()"
      ><ion-icon name="custom-ordered-list"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="toggleBlockquote()"
      ><ion-icon name="custom-blockquote"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="toggleInlineCode()"
      ><ion-icon name="custom-code-view"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="toggleCodeBlock()"
      ><ion-icon name="custom-code-block"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="addLink()"
      ><ion-icon name="custom-link"></ion-icon
    ></ion-button>
    <ion-button fill="clear" class="btn-small btn-formatting" color="dark" (click)="clearFormatting()"
      ><ion-icon name="custom-format-clear"></ion-icon
    ></ion-button>
  </div>
</div>
